<template>

      <v-container fluid style="height: 80%;">

        <v-row align="center" justify="center" style="height: 100%;">
          <v-col cols="12" sm="6" md="4">
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title >Formulario de ingreso</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
      
              <v-form @submit.prevent="login" id="login-form">
              <v-card-text>
                  <v-text-field id="email" prepend-icon="mdi-account" name="email" label="Correo electrónico" type="text" v-model="user.email"></v-text-field>
                  <v-text-field id="password" prepend-icon="mdi-lock" name="password" label="Contraseña" type="password" v-model="user.password"></v-text-field>
              </v-card-text>
              
              <v-card-actions>
                <v-row>
                    <v-col cols="12">
                        <v-btn dark block large color="black" form="login-form" type="submit"> <v-icon left>mdi-login</v-icon> Ingresar</v-btn>
                    </v-col>
                    <v-col cols="12">
                        <v-btn dark block large color="black" @click="modalSignup = true"><v-icon left>mdi-clipboard-account</v-icon> Crear cuenta</v-btn>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <a @click="resetModal = true" style="color: #3371e7; text-decoration: none;">¿Olvidaste tu contraseña?</a>
                    </v-col>
                </v-row>
              </v-card-actions>

              </v-form>

            </v-card>

              <div v-if="error">
                  <v-alert
                    v-model="alert"
                    dismissible
                    type="error"
                  >
                    {{ error.message }}
                  </v-alert>
              </div>

              <div v-if="success">
                  <v-alert
                    v-model="alertSuccess"
                    dismissible
                    type="success"
                  >
                    {{ success.message }}
                  </v-alert>
              </div>

          </v-col>
        </v-row>

    
        <v-dialog
        v-model="resetModal"
        max-width="500"
        scrollable
        >
        <v-card>
            <v-toolbar color="primary">
            <v-btn icon @click="resetModal = false">
                <v-icon>mdi-arrow-left-circle</v-icon>
            </v-btn>
            <v-toolbar-title>Regresar</v-toolbar-title>
            </v-toolbar>
            <v-form
                ref="form"
                
            >
            <v-card-title 
                class="headline grey lighten-2"
                primary-title
                style="color: #4a4a4a;"
            >
            Recuperar contraseña
            </v-card-title>
            
            <v-card-text>
                <v-text-field 
                prepend-icon="mdi-account" 
                name="email" 
                label="Correo electrónico" 
                type="text" 
                v-model="emailReset"
                hint="Por favor ingrese la dirección de correo electrónico que utilizó en el registro para que podamos enviarle un enlace de restablecimiento de contraseña."
                persistent-hint>
                </v-text-field>
            </v-card-text>
            </v-form>
            
            <v-divider ma-0 style="margin-left:1px;"></v-divider>
            <v-card-actions style="padding: 15px;">
                <v-row wrap>                    
                    <v-btn dark block large color="#3371e7" @click="resetPassword">Enviar</v-btn>
                </v-row>
            </v-card-actions>
            
        </v-card>
        </v-dialog>

        <v-dialog
        v-model="modalSignup"
        max-width="500"
        persistent
        >
        <v-card>
            <v-toolbar color="primary">
            <v-toolbar-title class="white--text">Nueva cuenta</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="modalSignup = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-toolbar>
            <v-form
                ref="form"
                
            >
            <v-card-text>
                  <v-text-field
                    v-model="name"
                    :error-messages="nameErrors"
                    label="Nombre completo"
                    required
                    outlined
                    @input="$v.name.$touch()"
                    @blur="$v.name.$touch()"
                    dense
                  ></v-text-field>

                  <v-text-field
                    v-model.trim="email"
                    :error-messages="emailErrors"
                    label="E-mail"
                    required
                    outlined
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                    dense
                  ></v-text-field>

                  <!--<v-text-field
                    v-model.trim="phone"
                    :error-messages="phoneErrors"
                    :counter="10"
                    label="Celular"
                    required
                    outlined
                    @input="$v.phone.$touch()"
                    @blur="$v.phone.$touch()"
                    dense
                  ></v-text-field>-->

                  <VuePhoneNumberInput 
                    v-model.trim="phone"
                    default-country-code="CO"
                    :translations="{
                        countrySelectorLabel: 'Código país',
                        countrySelectorError: 'Seleccione un país',
                        phoneNumberLabel: 'Número de telefono',
                        example: 'Ejemplo :'
                    }"
                    required
                    @update="validPhonePayload"
                    class="mb-5"
                  />

                  <v-text-field
                    :append-icon="show4 ? 'mdi-eye-off' : 'mdi-eye'"
                    v-model.trim="password"
                    :type="show4 ? 'text' : 'password'"
                    :error-messages="passwordErrors"
                    label="Contraseña"
                    required
                    outlined
                    @input="$v.password.$touch()"
                    @blur="$v.password.$touch()"
                    @click:append="show4 = !show4"
                    dense
                  ></v-text-field>

                  <v-text-field
                    v-model="company"
                    :error-messages="companyErrors"
                    label="Nombre de la empresa"
                    required
                    outlined
                    @input="$v.company.$touch()"
                    @blur="$v.company.$touch()"
                    dense
                  ></v-text-field>

                  <v-autocomplete
                    v-model="country"
                    :items="countries"
                    item-text="name"
                    return-object
                    outlined
                    label="Seleccione su País"
                    persistent-hint
                    :error-messages="countryErrors"
                    @input="$v.country.$touch()"
                    @blur="$v.country.$touch()"
                  ></v-autocomplete>

                  <v-text-field
                    v-model="city"
                    :error-messages="cityErrors"
                    label="Ciudad"
                    required
                    outlined
                    @input="$v.city.$touch()"
                    @blur="$v.city.$touch()"
                    dense
                  ></v-text-field>

                  <v-text-field
                    v-model="address"
                    :error-messages="addressErrors"
                    label="Dirección"
                    required
                    outlined
                    @input="$v.address.$touch()"
                    @blur="$v.address.$touch()"
                    dense
                  ></v-text-field>

                  <v-checkbox
                    v-model="checkbox"
                    :error-messages="checkboxErrors"
                    required
                    @change="$v.checkbox.$touch()"
                    @blur="$v.checkbox.$touch()"
                    dense
                  >
                  <template v-slot:label>
                    <div>
                      He leído y acepto los 
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a
                            target="_blank"
                            href="/terms"
                            @click.stop
                            v-on="on"
                          >
                            términos y condiciones del servicio
                          </a>
                        </template>
                        Clic para ver términos y condiciones
                      </v-tooltip>
                    </div>
                  </template>
                </v-checkbox>
                
            </v-card-text>
            </v-form>
            
            <v-divider ma-0 style="margin-left:1px;"></v-divider>
            <v-card-actions style="padding: 15px;">
                <v-row wrap>                    
                    <v-btn dark block large @click="signUpFalcon()" :loading="loading">Enviar</v-btn>
                </v-row>
            </v-card-actions>
            
        </v-card>
        </v-dialog>


      </v-container>

</template>

<script>
/* eslint-disable */
import Parse from 'parse'
import { validationMixin } from 'vuelidate'
//eslint-disable-next-line
import { required, minLength, numeric, email } from 'vuelidate/lib/validators'
import VuePhoneNumberInput from 'vue-phone-number-input';
//const isPhone = (value) => /^3[0-3]\d{8}$/.test(value); 

  export default {

    components: {
      VuePhoneNumberInput
    },

    mounted (){
      //console.log(Parse.User.current())
      this.$store.state.admin_view = true
      this.$store.state.isValueDeterminate = false
      this.$store.state.menuAdminActive = true
      if(Parse.User.current()){
         this.$router.push('/control')
      }
    },

    mixins: [validationMixin],

    validations: {
      name: { required },
      email: { required, email },
      password: { required },
      company: { required },
      city: { required },
      country: { required },
      address: { required },
      checkbox: {
        required,
        checked (val) {
          return val
        }
      }
    },

    data: () => ({
      drawer: null,
      user: {
        email: null,
        password: null
      },
      error: null,
      alert: true,
      success: null,
      alertSuccess: true,
      emailReset: '',
      resetModal: false,
      modalSignup: false,

      name: '',
      identification: '',
      email: '',
      password: '',
      phone: '',
      checkbox: false,
      company: '',
      city: '',
      address: '',
      show4: false,
      loading: false,
      country: '',
      countries: [
        {
            "name": "Afghanistan",
            "code": "AF"
        },
        {
            "name": "Åland Islands",
            "code": "AX"
        },
        {
            "name": "Albania",
            "code": "AL"
        },
        {
            "name": "Algeria",
            "code": "DZ"
        },
        {
            "name": "American Samoa",
            "code": "AS"
        },
        {
            "name": "AndorrA",
            "code": "AD"
        },
        {
            "name": "Angola",
            "code": "AO"
        },
        {
            "name": "Anguilla",
            "code": "AI"
        },
        {
            "name": "Antarctica",
            "code": "AQ"
        },
        {
            "name": "Antigua and Barbuda",
            "code": "AG"
        },
        {
            "name": "Argentina",
            "code": "AR"
        },
        {
            "name": "Armenia",
            "code": "AM"
        },
        {
            "name": "Aruba",
            "code": "AW"
        },
        {
            "name": "Australia",
            "code": "AU"
        },
        {
            "name": "Austria",
            "code": "AT"
        },
        {
            "name": "Azerbaijan",
            "code": "AZ"
        },
        {
            "name": "Bahamas",
            "code": "BS"
        },
        {
            "name": "Bahrain",
            "code": "BH"
        },
        {
            "name": "Bangladesh",
            "code": "BD"
        },
        {
            "name": "Barbados",
            "code": "BB"
        },
        {
            "name": "Belarus",
            "code": "BY"
        },
        {
            "name": "Belgium",
            "code": "BE"
        },
        {
            "name": "Belize",
            "code": "BZ"
        },
        {
            "name": "Benin",
            "code": "BJ"
        },
        {
            "name": "Bermuda",
            "code": "BM"
        },
        {
            "name": "Bhutan",
            "code": "BT"
        },
        {
            "name": "Bolivia",
            "code": "BO"
        },
        {
            "name": "Bosnia and Herzegovina",
            "code": "BA"
        },
        {
            "name": "Botswana",
            "code": "BW"
        },
        {
            "name": "Bouvet Island",
            "code": "BV"
        },
        {
            "name": "Brazil",
            "code": "BR"
        },
        {
            "name": "British Indian Ocean Territory",
            "code": "IO"
        },
        {
            "name": "Brunei Darussalam",
            "code": "BN"
        },
        {
            "name": "Bulgaria",
            "code": "BG"
        },
        {
            "name": "Burkina Faso",
            "code": "BF"
        },
        {
            "name": "Burundi",
            "code": "BI"
        },
        {
            "name": "Cambodia",
            "code": "KH"
        },
        {
            "name": "Cameroon",
            "code": "CM"
        },
        {
            "name": "Canada",
            "code": "CA"
        },
        {
            "name": "Cape Verde",
            "code": "CV"
        },
        {
            "name": "Cayman Islands",
            "code": "KY"
        },
        {
            "name": "Central African Republic",
            "code": "CF"
        },
        {
            "name": "Chad",
            "code": "TD"
        },
        {
            "name": "Chile",
            "code": "CL"
        },
        {
            "name": "China",
            "code": "CN"
        },
        {
            "name": "Christmas Island",
            "code": "CX"
        },
        {
            "name": "Cocos (Keeling) Islands",
            "code": "CC"
        },
        {
            "name": "Colombia",
            "code": "CO"
        },
        {
            "name": "Comoros",
            "code": "KM"
        },
        {
            "name": "Congo",
            "code": "CG"
        },
        {
            "name": "Congo, The Democratic Republic of the",
            "code": "CD"
        },
        {
            "name": "Cook Islands",
            "code": "CK"
        },
        {
            "name": "Costa Rica",
            "code": "CR"
        },
        {
            "name": "Cote D\"Ivoire",
            "code": "CI"
        },
        {
            "name": "Croatia",
            "code": "HR"
        },
        {
            "name": "Cuba",
            "code": "CU"
        },
        {
            "name": "Cyprus",
            "code": "CY"
        },
        {
            "name": "Czech Republic",
            "code": "CZ"
        },
        {
            "name": "Denmark",
            "code": "DK"
        },
        {
            "name": "Djibouti",
            "code": "DJ"
        },
        {
            "name": "Dominica",
            "code": "DM"
        },
        {
            "name": "Dominican Republic",
            "code": "DO"
        },
        {
            "name": "Ecuador",
            "code": "EC"
        },
        {
            "name": "Egypt",
            "code": "EG"
        },
        {
            "name": "El Salvador",
            "code": "SV"
        },
        {
            "name": "Equatorial Guinea",
            "code": "GQ"
        },
        {
            "name": "Eritrea",
            "code": "ER"
        },
        {
            "name": "Estonia",
            "code": "EE"
        },
        {
            "name": "Ethiopia",
            "code": "ET"
        },
        {
            "name": "Falkland Islands (Malvinas)",
            "code": "FK"
        },
        {
            "name": "Faroe Islands",
            "code": "FO"
        },
        {
            "name": "Fiji",
            "code": "FJ"
        },
        {
            "name": "Finland",
            "code": "FI"
        },
        {
            "name": "France",
            "code": "FR"
        },
        {
            "name": "French Guiana",
            "code": "GF"
        },
        {
            "name": "French Polynesia",
            "code": "PF"
        },
        {
            "name": "French Southern Territories",
            "code": "TF"
        },
        {
            "name": "Gabon",
            "code": "GA"
        },
        {
            "name": "Gambia",
            "code": "GM"
        },
        {
            "name": "Georgia",
            "code": "GE"
        },
        {
            "name": "Germany",
            "code": "DE"
        },
        {
            "name": "Ghana",
            "code": "GH"
        },
        {
            "name": "Gibraltar",
            "code": "GI"
        },
        {
            "name": "Greece",
            "code": "GR"
        },
        {
            "name": "Greenland",
            "code": "GL"
        },
        {
            "name": "Grenada",
            "code": "GD"
        },
        {
            "name": "Guadeloupe",
            "code": "GP"
        },
        {
            "name": "Guam",
            "code": "GU"
        },
        {
            "name": "Guatemala",
            "code": "GT"
        },
        {
            "name": "Guernsey",
            "code": "GG"
        },
        {
            "name": "Guinea",
            "code": "GN"
        },
        {
            "name": "Guinea-Bissau",
            "code": "GW"
        },
        {
            "name": "Guyana",
            "code": "GY"
        },
        {
            "name": "Haiti",
            "code": "HT"
        },
        {
            "name": "Heard Island and Mcdonald Islands",
            "code": "HM"
        },
        {
            "name": "Holy See (Vatican City State)",
            "code": "VA"
        },
        {
            "name": "Honduras",
            "code": "HN"
        },
        {
            "name": "Hong Kong",
            "code": "HK"
        },
        {
            "name": "Hungary",
            "code": "HU"
        },
        {
            "name": "Iceland",
            "code": "IS"
        },
        {
            "name": "India",
            "code": "IN"
        },
        {
            "name": "Indonesia",
            "code": "ID"
        },
        {
            "name": "Iran, Islamic Republic Of",
            "code": "IR"
        },
        {
            "name": "Iraq",
            "code": "IQ"
        },
        {
            "name": "Ireland",
            "code": "IE"
        },
        {
            "name": "Isle of Man",
            "code": "IM"
        },
        {
            "name": "Israel",
            "code": "IL"
        },
        {
            "name": "Italy",
            "code": "IT"
        },
        {
            "name": "Jamaica",
            "code": "JM"
        },
        {
            "name": "Japan",
            "code": "JP"
        },
        {
            "name": "Jersey",
            "code": "JE"
        },
        {
            "name": "Jordan",
            "code": "JO"
        },
        {
            "name": "Kazakhstan",
            "code": "KZ"
        },
        {
            "name": "Kenya",
            "code": "KE"
        },
        {
            "name": "Kiribati",
            "code": "KI"
        },
        {
            "name": "Korea, Democratic People\"S Republic of",
            "code": "KP"
        },
        {
            "name": "Korea, Republic of",
            "code": "KR"
        },
        {
            "name": "Kuwait",
            "code": "KW"
        },
        {
            "name": "Kyrgyzstan",
            "code": "KG"
        },
        {
            "name": "Lao People\"S Democratic Republic",
            "code": "LA"
        },
        {
            "name": "Latvia",
            "code": "LV"
        },
        {
            "name": "Lebanon",
            "code": "LB"
        },
        {
            "name": "Lesotho",
            "code": "LS"
        },
        {
            "name": "Liberia",
            "code": "LR"
        },
        {
            "name": "Libyan Arab Jamahiriya",
            "code": "LY"
        },
        {
            "name": "Liechtenstein",
            "code": "LI"
        },
        {
            "name": "Lithuania",
            "code": "LT"
        },
        {
            "name": "Luxembourg",
            "code": "LU"
        },
        {
            "name": "Macao",
            "code": "MO"
        },
        {
            "name": "Macedonia, The Former Yugoslav Republic of",
            "code": "MK"
        },
        {
            "name": "Madagascar",
            "code": "MG"
        },
        {
            "name": "Malawi",
            "code": "MW"
        },
        {
            "name": "Malaysia",
            "code": "MY"
        },
        {
            "name": "Maldives",
            "code": "MV"
        },
        {
            "name": "Mali",
            "code": "ML"
        },
        {
            "name": "Malta",
            "code": "MT"
        },
        {
            "name": "Marshall Islands",
            "code": "MH"
        },
        {
            "name": "Martinique",
            "code": "MQ"
        },
        {
            "name": "Mauritania",
            "code": "MR"
        },
        {
            "name": "Mauritius",
            "code": "MU"
        },
        {
            "name": "Mayotte",
            "code": "YT"
        },
        {
            "name": "Mexico",
            "code": "MX"
        },
        {
            "name": "Micronesia, Federated States of",
            "code": "FM"
        },
        {
            "name": "Moldova, Republic of",
            "code": "MD"
        },
        {
            "name": "Monaco",
            "code": "MC"
        },
        {
            "name": "Mongolia",
            "code": "MN"
        },
        {
            "name": "Montserrat",
            "code": "MS"
        },
        {
            "name": "Morocco",
            "code": "MA"
        },
        {
            "name": "Mozambique",
            "code": "MZ"
        },
        {
            "name": "Myanmar",
            "code": "MM"
        },
        {
            "name": "Namibia",
            "code": "NA"
        },
        {
            "name": "Nauru",
            "code": "NR"
        },
        {
            "name": "Nepal",
            "code": "NP"
        },
        {
            "name": "Netherlands",
            "code": "NL"
        },
        {
            "name": "Netherlands Antilles",
            "code": "AN"
        },
        {
            "name": "New Caledonia",
            "code": "NC"
        },
        {
            "name": "New Zealand",
            "code": "NZ"
        },
        {
            "name": "Nicaragua",
            "code": "NI"
        },
        {
            "name": "Niger",
            "code": "NE"
        },
        {
            "name": "Nigeria",
            "code": "NG"
        },
        {
            "name": "Niue",
            "code": "NU"
        },
        {
            "name": "Norfolk Island",
            "code": "NF"
        },
        {
            "name": "Northern Mariana Islands",
            "code": "MP"
        },
        {
            "name": "Norway",
            "code": "NO"
        },
        {
            "name": "Oman",
            "code": "OM"
        },
        {
            "name": "Pakistan",
            "code": "PK"
        },
        {
            "name": "Palau",
            "code": "PW"
        },
        {
            "name": "Palestinian Territory, Occupied",
            "code": "PS"
        },
        {
            "name": "Panama",
            "code": "PA"
        },
        {
            "name": "Papua New Guinea",
            "code": "PG"
        },
        {
            "name": "Paraguay",
            "code": "PY"
        },
        {
            "name": "Peru",
            "code": "PE"
        },
        {
            "name": "Philippines",
            "code": "PH"
        },
        {
            "name": "Pitcairn",
            "code": "PN"
        },
        {
            "name": "Poland",
            "code": "PL"
        },
        {
            "name": "Portugal",
            "code": "PT"
        },
        {
            "name": "Puerto Rico",
            "code": "PR"
        },
        {
            "name": "Qatar",
            "code": "QA"
        },
        {
            "name": "Reunion",
            "code": "RE"
        },
        {
            "name": "Romania",
            "code": "RO"
        },
        {
            "name": "Russian Federation",
            "code": "RU"
        },
        {
            "name": "RWANDA",
            "code": "RW"
        },
        {
            "name": "Saint Helena",
            "code": "SH"
        },
        {
            "name": "Saint Kitts and Nevis",
            "code": "KN"
        },
        {
            "name": "Saint Lucia",
            "code": "LC"
        },
        {
            "name": "Saint Pierre and Miquelon",
            "code": "PM"
        },
        {
            "name": "Saint Vincent and the Grenadines",
            "code": "VC"
        },
        {
            "name": "Samoa",
            "code": "WS"
        },
        {
            "name": "San Marino",
            "code": "SM"
        },
        {
            "name": "Sao Tome and Principe",
            "code": "ST"
        },
        {
            "name": "Saudi Arabia",
            "code": "SA"
        },
        {
            "name": "Senegal",
            "code": "SN"
        },
        {
            "name": "Serbia and Montenegro",
            "code": "CS"
        },
        {
            "name": "Seychelles",
            "code": "SC"
        },
        {
            "name": "Sierra Leone",
            "code": "SL"
        },
        {
            "name": "Singapore",
            "code": "SG"
        },
        {
            "name": "Slovakia",
            "code": "SK"
        },
        {
            "name": "Slovenia",
            "code": "SI"
        },
        {
            "name": "Solomon Islands",
            "code": "SB"
        },
        {
            "name": "Somalia",
            "code": "SO"
        },
        {
            "name": "South Africa",
            "code": "ZA"
        },
        {
            "name": "South Georgia and the South Sandwich Islands",
            "code": "GS"
        },
        {
            "name": "Spain",
            "code": "ES"
        },
        {
            "name": "Sri Lanka",
            "code": "LK"
        },
        {
            "name": "Sudan",
            "code": "SD"
        },
        {
            "name": "Suriname",
            "code": "SR"
        },
        {
            "name": "Svalbard and Jan Mayen",
            "code": "SJ"
        },
        {
            "name": "Swaziland",
            "code": "SZ"
        },
        {
            "name": "Sweden",
            "code": "SE"
        },
        {
            "name": "Switzerland",
            "code": "CH"
        },
        {
            "name": "Syrian Arab Republic",
            "code": "SY"
        },
        {
            "name": "Taiwan, Province of China",
            "code": "TW"
        },
        {
            "name": "Tajikistan",
            "code": "TJ"
        },
        {
            "name": "Tanzania, United Republic of",
            "code": "TZ"
        },
        {
            "name": "Thailand",
            "code": "TH"
        },
        {
            "name": "Timor-Leste",
            "code": "TL"
        },
        {
            "name": "Togo",
            "code": "TG"
        },
        {
            "name": "Tokelau",
            "code": "TK"
        },
        {
            "name": "Tonga",
            "code": "TO"
        },
        {
            "name": "Trinidad and Tobago",
            "code": "TT"
        },
        {
            "name": "Tunisia",
            "code": "TN"
        },
        {
            "name": "Turkey",
            "code": "TR"
        },
        {
            "name": "Turkmenistan",
            "code": "TM"
        },
        {
            "name": "Turks and Caicos Islands",
            "code": "TC"
        },
        {
            "name": "Tuvalu",
            "code": "TV"
        },
        {
            "name": "Uganda",
            "code": "UG"
        },
        {
            "name": "Ukraine",
            "code": "UA"
        },
        {
            "name": "United Arab Emirates",
            "code": "AE"
        },
        {
            "name": "United Kingdom",
            "code": "GB"
        },
        {
            "name": "United States",
            "code": "US"
        },
        {
            "name": "United States Minor Outlying Islands",
            "code": "UM"
        },
        {
            "name": "Uruguay",
            "code": "UY"
        },
        {
            "name": "Uzbekistan",
            "code": "UZ"
        },
        {
            "name": "Vanuatu",
            "code": "VU"
        },
        {
            "name": "Venezuela",
            "code": "VE"
        },
        {
            "name": "Viet Nam",
            "code": "VN"
        },
        {
            "name": "Virgin Islands, British",
            "code": "VG"
        },
        {
            "name": "Virgin Islands, U.S.",
            "code": "VI"
        },
        {
            "name": "Wallis and Futuna",
            "code": "WF"
        },
        {
            "name": "Western Sahara",
            "code": "EH"
        },
        {
            "name": "Yemen",
            "code": "YE"
        },
        {
            "name": "Zambia",
            "code": "ZM"
        },
        {
            "name": "Zimbabwe",
            "code": "ZW"
        }
    ],
    payload: ''
    }),

    props: {
      source: String
    },

    computed: {
      checkboxErrors () {
        const errors = []
        if (!this.$v.checkbox.$dirty) return errors
        !this.$v.checkbox.checked && errors.push('Debes aceptar los terminos y condiciones.')
        return errors
      },
      passwordErrors () {
        const errors = []
        if (!this.$v.password.$dirty) return errors
        !this.$v.password.required && errors.push('El password es requerido.')
        return errors
      },
      nameErrors () {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        //!this.$v.name.minLength && errors.push('El nombre debe contener al menos 10 caracteres.')
        !this.$v.name.required && errors.push('Nombre es requerido.')
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.email && errors.push('El email no es valido.')
        !this.$v.email.required && errors.push('E-mail es requerido.')
        return errors
      },
      /*phoneErrors () {
        const errors = []
        if (!this.$v.phone.$dirty) return errors
        //!this.$v.phone.phoneValid && errors.push('El celular no es valido.')
        !this.$v.phone.required && errors.push('Celular es requerido.')
        !this.$v.phone.numeric && errors.push('Celular solo debe contener números.')
        return errors
      },*/

      companyErrors () {
        const errors = []
        if (!this.$v.company.$dirty) return errors
        //!this.$v.name.minLength && errors.push('El nombre debe contener al menos 10 caracteres.')
        !this.$v.company.required && errors.push('Nomdre de la empresa es requerido.')
        return errors
      },

      cityErrors () {
        const errors = []
        if (!this.$v.city.$dirty) return errors
        //!this.$v.city.minLength && errors.push('El nombre debe contener al menos 10 caracteres.')
        !this.$v.city.required && errors.push('Ciudad es requerido.')
        return errors
      },

      countryErrors () {
        const errors = []
        if (!this.$v.country.$dirty) return errors
        //!this.$v.country.minLength && errors.push('El nombre debe contener al menos 10 caracteres.')
        !this.$v.country.required && errors.push('País es requerido.')
        return errors
      },

      addressErrors () {
        const errors = []
        if (!this.$v.address.$dirty) return errors
        //!this.$v.address.minLength && errors.push('El nombre debe contener al menos 10 caracteres.')
        !this.$v.address.required && errors.push('Dirección es requerido.')
        return errors
      },
    },

    methods: {
      async login () {
        let self = this
        self.error = null

        try {

          if (!self.user.email || !self.user.password) {
            self.error = { message: "Los campos no deben estar vacios" };
            throw self.error;
          }

          let email_login = self.user.email.trim();
          email_login = email_login.toLowerCase();

          await Parse.User.logIn(email_login, self.user.password);
          const loggedIn = Parse.User.current();
          
          /*OneSignal.push(function() {
            OneSignal.setExternalUserId(loggedIn.id);
          });*/

          //self.$emit('loginSuccess', res)
          //const isAdmin = loggedIn.attributes.assistant;
          /*if(!isAdmin){
            Parse.User.logOut()
            //this.loggedUser = null
            throw self.error = { message: "No eres administrador." };
          }*/

          this.$router.go('/control')
          //window.location.href = this.$router.history._startLocation;

        } catch (error) {
          this.alert = true
          this.error = error
          console.log(error.message)
        }
      },
      resetPassword(){

        if (!this.emailReset) {
            this.$snotify.error('Debe diligenciar correctamente el formulario.' , 'Formulario', {
                  timeout: 4000,
                  preventDuplicates: true
                });
            return;
        }
        Parse.User.requestPasswordReset(this.emailReset)
            .then(() => {

                this.$snotify.success('Correo de recuperación enviado correctamente.' , 'Enviado', {
                  timeout: 4000,
                  preventDuplicates: true
                });

                this.resetModal = false
            
            }).catch((error) => {
            // Show the error message somewhere
            this.$snotify.error(error.message , error.code, {
              timeout: 4000,
              preventDuplicates: true
            });
        });
      },

      async signUpFalcon(){
        this.loading = true

        this.$v.$touch()
          if(!this.$v.$invalid) {

            if (!this.payload.isValid) {
              this.$snotify.error('El celular no es válido' , 'Registro', {
                      timeout: 4000,
                preventDuplicates: true
              });
              this.loading = false
              return;
            }

            let params = {
              name: this.name,
              password: this.password,
              email: this.email,
              username: this.email,
              phone: this.payload.nationalNumber,
              os: 'web',

              company: this.company,
              city: this.city,
              country: this.country.name,
              countryCode: this.country.code,
              phoneCode: this.payload.countryCallingCode,
              address: this.address
            }

            try {
              let user = await Parse.Cloud.run("registerFalconUser", params)
              //console.log(user);
              //const loggedIn = Parse.User.current();
              /*OneSignal.push(function() {
                OneSignal.setExternalUserId(loggedIn.id);
              });*/
                        
              this.$snotify.success('Registro exitoso.' , 'Registro', {
                timeout: 4000,
                preventDuplicates: true
              });

              this.loading = false

              Parse.User.become(user.attributes.sessionToken).then(function (user) {
                    window.location.href = '/control'
                }, function (error) {
                    //eslint-disable-next-line
                    //console.log(error);
                    this.$snotify.error('Ocurrio un error en el inicio de sesión' , 'Registro', {
                      timeout: 4000,
                      preventDuplicates: true
                    }); 
              });
              
              //this.$router.push(this.$router.history._startLocation)
              //window.location.href = this.$router.history._startLocation;

            } catch (error) {
              this.$snotify.error(error.message , 'Registro', {
                timeout: 4000,
                preventDuplicates: true
              }); 
            }
          }else{
            this.$snotify.error('Formulario invalido.' , 'Registro', {
              timeout: 4000,
              preventDuplicates: true
            }); 
            this.loading = false
          }
      },

      validPhonePayload(payload){
        this.payload = payload
      },

      async test(){
            this.loading = true
            try {
                let test = await Parse.Cloud.run("test")
                console.log(test);

            } catch (error) {
                this.$snotify.error(error.message , 'Test', {
                    timeout: 4000,
                    preventDuplicates: true
                 }); 
            }
        },
    }
  }
</script>